import { Redirect } from "react-router-dom";
import routes, { pageNames } from "../constants/routes";
import ErrorPage from "../components/pages/error";
import DocumentsPage from "../components/pages/documents";
import LoginPage from "../components/pages/user/Login";
import SignupPage from "../components/pages/user/Signup";
import ProfilePage from "../components/pages/user/profile";
import DocumentAnalysis from "../components/pages/analysis";
import OrganisationPage from "../components/pages/organisation";
import MetadataPage from "../components/pages/metadata";
import DocumentSource from "../components/pages/documents/DocumentSource";
import { appRoles } from "../constants";
import STIXGraph from "../components/pages/stixGraph";
import Collections from "../components/pages/collections";
import CollectionGraph from "../components/pages/collections/CollectionGraph";
import RulesManager from "../components/pages/rulesManager";
import PerformanceDashboard from "../components/pages/performanceDashboard";
import { performanceDashboardSupported } from "../utils/helpers";

export const pageRoutes = [
  {
    path: routes.HOME,
    Component: () => <Redirect to={routes.DOCUMENTS} />,
    exact: true,
    trackPage: false,
    pageName: pageNames[routes.HOME],
  },
  {
    path: routes.SIGNUP,
    Component: SignupPage,
    exact: true,
    trackPage: false,
    pageName: pageNames[routes.SIGNUP],
  },
  {
    path: routes.LOGIN,
    Component: LoginPage,
    exact: true,
    trackPage: true,
    pageName: pageNames[routes.LOGIN],
  },
  {
    path: routes.PROFILE,
    Component: ProfilePage,
    exact: true,
    trackPage: true,
    pageName: pageNames[routes.PROFILE],
  },
  {
    path: routes.DOCUMENTS,
    Component: DocumentsPage,
    exact: true,
    trackPage: true,
    pageName: pageNames[routes.DOCUMENTS],
  },
  {
    path: routes.DOCUMENT_ANALYSIS,
    Component: DocumentAnalysis,
    exact: true,
    trackPage: true,
    pageName: pageNames[routes.DOCUMENT_ANALYSIS],
  },
  {
    path: routes.STIX_GRAPH,
    Component: STIXGraph,
    exact: true,
    trackPage: true,
    pageName: pageNames[routes.STIX_GRAPH],
  },
  {
    path: routes.DOCUMENT_SOURCE,
    Component: DocumentSource,
    exact: true,
    trackPage: true,
    pageName: pageNames[routes.DOCUMENT_SOURCE],
  },
  {
    path: routes.ORGANISATION,
    Component: OrganisationPage,
    exact: true,
    trackPage: true,
    pageName: pageNames[routes.ORGANISATION],
    permissions: [appRoles.MANAGE_ORG_SETTINGS],
  },
  {
    path: routes.METADATA,
    Component: MetadataPage,
    exact: true,
    trackPage: true,
    pageName: pageNames[routes.METADATA],
    permissions: [appRoles.ORGANISATION_METADATA],
  },
  {
    path: routes.COLLECTIONS,
    Component: Collections,
    exact: true,
    trackPage: true,
    pageName: pageNames[routes.COLLECTIONS],
    permissions: [appRoles.MANAGE_ORG_COLLECTIONS],
  },
  {
    path: routes.COLLECTION_GRAPH,
    Component: CollectionGraph,
    exact: true,
    trackPage: true,
    pageName: pageNames[routes.COLLECTION_GRAPH],
    permissions: [appRoles.MANAGE_ORG_COLLECTIONS],
  },
  {
    path: routes.RULES_MANAGER,
    Component: RulesManager,
    exact: true,
    trackPage: true,
    pageName: pageNames[routes.RULES_MANAGER],
  },
  {
    path: routes.PERFORMANCE_DASHBOARD,
    Component: PerformanceDashboard,
    exact: true,
    trackPage: true,
    exclude: !performanceDashboardSupported(),
    pageName: pageNames[routes.PERFORMANCE_DASHBOARD],
  },
];

export const errorRoutes = [
  {
    path: routes.NOT_FOUND,
    Component: ErrorPage,
    exact: true,
    trackPage: true,
    pageName: pageNames[routes.NOT_FOUND],
  },
  {
    path: routes.NOT_AUTHENTICATED,
    Component: ErrorPage,
    exact: true,
    trackPage: true,
    pageName: pageNames[routes.NOT_AUTHENTICATED],
  },
  {
    path: routes.FORBIDDEN,
    Component: ErrorPage,
    exact: true,
    trackPage: true,
    pageName: pageNames[routes.FORBIDDEN],
  },
  {
    path: routes.INTERNAL_ERROR,
    Component: ErrorPage,
    exact: true,
    trackPage: true,
    pageName: pageNames[routes.INTERNAL_ERROR],
  },
  {
    path: routes.FALL_BACK,
    Component: ErrorPage,
    trackPage: true,
    pageName: pageNames[routes.FALL_BACK],
  },
];
