const successMessages = {
  entity_accepted: "Entity accepted",
  entity_rejected: "Entity rejected",
  entity_added: "Entity added",
  entity_added_accepted: "Entity added and accepted",
  entity_rejected_rule: "Entity rejected and added to rule",
  document_removed: "Document removed",
  url_queued: "URL document added to queue",
};

export default successMessages;
