import { Menu } from "antd";
import { LikeOutlined, DislikeOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { entityStatus } from "../../../../constants/index.js";
import { handleAcceptRejectEntityMenu } from "../helpers.js";
import { RejectEntityMenuItems } from "../SharedComponents.jsx";

const { ACCEPTED, REJECTED } = entityStatus;
/**
 * Confirm entity menu
 *
 * @param {object} selectedEntity The selected entity data
 * @returns React JSX
 */
const ConfirmEntityMenu = ({ selectedEntity }) => {
  const dispatch = useDispatch();

  // App analysis state
  const { analysis } = useSelector((state) => state);
  const { document, entitiesByIds } = analysis;

  const currentStatus = entitiesByIds[selectedEntity.id]
    ? entitiesByIds[selectedEntity.id].status
    : "";

  return (
    <div className="accept-or-reject">
      <Menu
        onClick={handleAcceptRejectEntityMenu(
          selectedEntity.id,
          document.id,
          dispatch
        )}
      >
        {currentStatus !== ACCEPTED && (
          <>
            <Menu.Item
              icon={<LikeOutlined className="f-green-d" />}
              disabled={currentStatus === ACCEPTED}
              key={ACCEPTED}
            >
              Accept{" "}
            </Menu.Item>
            <Menu.Divider />
          </>
        )}
        <Menu.SubMenu
          key={REJECTED}
          icon={<DislikeOutlined className="f-red" />}
          title="Reject"
        >
          <RejectEntityMenuItems
            onClick={handleAcceptRejectEntityMenu(
              selectedEntity.id,
              document.id,
              dispatch
            )}
          />
        </Menu.SubMenu>
      </Menu>
    </div>
  );
};

export default ConfirmEntityMenu;
