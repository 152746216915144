import { useSelector, useDispatch } from "react-redux";
import { Divider, Menu } from "antd";
import { BulbFilled, BugOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { getTableColumnRowFromRef } from "../../../../utils/helpers/analysis";
import attackTechniques from "../../../../data/attack_ids_v10_and_disarm";
import {
  ATTACK_PATTERN,
  blockType,
  entityClass,
  entityScope,
  SDO_LOCATION,
} from "../../../../constants";
import {
  makeVEPrefix,
  sendNewEntityRequest,
  virtualEntityFromNewPayload,
} from "../../../../utils/helpers/analysis/entities";
import DisplayTip from "../../../common/widgets/DisplayTip";
import infoMessages from "../../../../utils/messages/infoMessages";
import SelectTID from "./SelectTID";
import { useState } from "react";
import { useEffect } from "react";
import { removeActiveSelection } from "../../../../store/actions";
import SelectCountry from "./SelectCountry";
import countries from "../../../../data/countries";

const { TABLE, TEXT } = entityClass;
const UNDETERMINED = "undetermined";

/**
 * Entity annotation menu
 *
 * @returns React JSX
 */
const EntityAnnotationMenu = () => {
  // Component state
  const [selectedTID, setSelectedTID] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("");

  const dispatch = useDispatch();
  // App state
  const { analysis, selection } = useSelector((state) => state);
  const { activeSelection, virtualEntities } = selection;

  /* Handles word or phrase level tagging */
  const handleEntityAnnotation = ({ keyPath }) => {
    let [sub_type, type] = keyPath;
    if (sub_type === UNDETERMINED) sub_type = undefined;
    let sub_properties = { sub_type };

    if (type === ATTACK_PATTERN) {
      sub_properties.id = selectedTID;
      sub_properties.tid = selectedTID;
      sub_properties.name = attackTechniques[selectedTID].name;
    }

    if (type === SDO_LOCATION) {
      sub_properties.code = selectedCountryCode;
      sub_properties.name = countries[selectedCountryCode];
    }

    const { blockId, selectedText, absoluteStart, absoluteEnd } =
      activeSelection;

    // Common payload
    let payload = {
      name: selectedText,
      type,
      sub_properties,
    };

    let virtualEntity;

    const [prefix, ref, blockIndex] = blockId.split("-");
    const vePrefix = makeVEPrefix(entityScope.INLINE, blockIndex);

    if (prefix === blockType.TABLE) {
      const { table_idx, column_idx, row_idx } = getTableColumnRowFromRef(ref);

      // Table payload
      payload = {
        ...payload,
        table_idx,
        column: column_idx,
        row: row_idx,
        start_position: absoluteStart,
        end_position: absoluteEnd,
        text: selectedText,
      };

      virtualEntity = virtualEntityFromNewPayload(payload, vePrefix, TABLE);
    } else {
      // Text payload
      payload = {
        ...payload,
        sentence_idx: +ref,
        start_position_in_sentence: absoluteStart,
        end_position_in_sentence: absoluteEnd,
        text: selectedText,
      };

      virtualEntity = virtualEntityFromNewPayload(payload, vePrefix, TEXT);
    }

    sendNewEntityRequest(
      payload,
      analysis.document.id,
      virtualEntity,
      virtualEntities,
      vePrefix,
      dispatch
    );
  };

  useEffect(() => {
    if (!selectedTID) return;
    handleEntityAnnotation({ keyPath: [selectedTID, ATTACK_PATTERN] });
    dispatch(removeActiveSelection());
  }, [selectedTID]);

  useEffect(() => {
    if (!selectedCountryCode) return;
    handleEntityAnnotation({ keyPath: [selectedCountryCode, SDO_LOCATION] });
    dispatch(removeActiveSelection());
  }, [selectedCountryCode]);

  return (
    <div>
      <Menu onClick={handleEntityAnnotation}>
        {Object.values(analysis.entityProps).map((entity) => (
          <Menu.SubMenu
            key={entity.value}
            icon={<BulbFilled style={{ color: entity.color }} />}
            title={entity.label}
            className="menu-submenu"
          >
            {entity.subcategories.length > 1 && (
              <>
                <Menu.Item
                  icon={<BugOutlined style={{ color: entity.color }} />}
                  key={UNDETERMINED}
                  className="sub-menu-item"
                >
                  {UNDETERMINED}{" "}
                  <DisplayTip title={infoMessages.select_undertermined_subtype}>
                    <InfoCircleOutlined />
                  </DisplayTip>
                </Menu.Item>
                <Divider className="my-0" />
              </>
            )}
            {entity.subcategories.map((type) => {
              if (type === ATTACK_PATTERN) {
                return (
                  <Menu.Item key={type} className="sub-menu-item">
                    <SelectTID
                      onClick={(e) => e.stopPropagation()}
                      selectedTID={selectedTID}
                      setSelectedTID={setSelectedTID}
                    />
                  </Menu.Item>
                );
              } else if (type === "country") {
                return (
                  <Menu.Item key={type} className="sub-menu-item">
                    <SelectCountry
                      onClick={(e) => e.stopPropagation()}
                      setSelectedCountryCode={setSelectedCountryCode}
                    />
                  </Menu.Item>
                );
              } else {
                return (
                  <Menu.Item
                    icon={<BugOutlined style={{ color: entity.color }} />}
                    key={type}
                    className="sub-menu-item"
                  >
                    {type}
                  </Menu.Item>
                );
              }
            })}
          </Menu.SubMenu>
        ))}
      </Menu>
    </div>
  );
};

export default EntityAnnotationMenu;
