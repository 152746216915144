import { Button, Divider } from "antd";
import { useSelector } from "react-redux";
import { useState } from "react";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { ATTACK_PATTERN, entityClass, SDO_LOCATION } from "../../../constants";
import DisplayTip from "../../common/widgets/DisplayTip";
import Annotation from "../../../utils/services/Annotation";
import { useEffect } from "react";
import EntitySelectInput from "./EntitySelectInput";
import { saveMultipleEntitiesType } from "../../../utils/helpers/analysis";
import { useDispatch } from "react-redux";
import infoMessages from "../../../utils/messages/infoMessages";
import countries from "../../../data/countries";
import attackTechniques from "../../../data/attack_tids_v14_and_disarm.json";

/**
 * EntityTypeOptions displays options for modifying entities
 *
 * @returns React JSX
 */
const EntityTypeOptions = ({ entityIds, disableList = false, entityText }) => {
  // Component state
  const [idCollection, setIdCollection] = useState(new Set());
  const [selectedType, setSelectedType] = useState("");
  const [selectedTID, setSelectedTID] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("");

  // App state
  const { analysis, analysisApi } = useSelector((state) => state);
  const { entitiesByIds, document } = analysis;
  const {
    changeMultipleEntities: { loading },
    analysisDetails,
  } = analysisApi;

  const dispatch = useDispatch();

  const entitiesCount = entityIds.length;

  let currentType = "";
  if (entitiesCount) currentType = entitiesByIds[entityIds[0]].type;

  useEffect(() => {
    setIdCollection(new Set(entityIds));
    setSelectedType(currentType);
  }, [entityIds]);

  useEffect(() => {
    if (!selectedTID) return;
    setSelectedType(ATTACK_PATTERN);
  }, [selectedTID]);

  useEffect(() => {
    if (!selectedCountryCode) return;
    setSelectedType(SDO_LOCATION);
  }, [selectedCountryCode]);

  const handleCheckbox = (entityId) => (e) => {
    const newCollection = new Set(idCollection);
    e.target.checked
      ? newCollection.add(entityId)
      : newCollection.delete(entityId);
    setIdCollection(newCollection);
  };

  const handleCheckAll = (e) =>
    e.target.checked
      ? setIdCollection(new Set(entityIds))
      : setIdCollection(new Set());

  const saveType = () => {
    if (!selectedType) return;
    const [type, sub_type] = selectedType.split("::");
    const payload = { type, sub_properties: { sub_type } };
    if (type === ATTACK_PATTERN) {
      payload.sub_properties = {
        sub_type,
        id: selectedTID,
        tid: selectedTID,
        name: attackTechniques[selectedTID].name,
      };
    } else if (type === SDO_LOCATION) {
      payload.sub_properties = {
        sub_type: selectedCountryCode,
        code: selectedCountryCode,
        name: countries[selectedCountryCode],
      };
    }
    saveMultipleEntitiesType(
      document.id,
      Array.from(idCollection),
      entityText,
      payload,
      dispatch
    );
  };

  const processing = loading || analysisDetails.loading;

  return (
    <div>
      <div className="section-card mb-15">
        <div className="section-card-body bd-0 px-0">
          <EntitySelectInput
            selectedType={selectedType}
            handleChange={(entityType) => setSelectedType(entityType)}
            selectedTID={selectedTID}
            setSelectedTID={setSelectedTID}
            selectedCountryCode={selectedCountryCode}
            setSelectedCountryCode={setSelectedCountryCode}
          />
          <div className="justify-end">
            <Button
              onClick={saveType}
              type="primary"
              loading={processing}
              disabled={
                !selectedType ||
                selectedType === currentType ||
                !idCollection.size
              }
            >
              Change
            </Button>
          </div>
        </div>
      </div>

      <div className="section-card">
        <div className="section-card-body">
          <Divider>
            <Checkbox
              onChange={handleCheckAll}
              checked={idCollection.size === entitiesCount}
              indeterminate={idCollection.size === entitiesCount}
              disabled={disableList || processing}
            >
              {infoMessages.count_selected.replace(
                ":count:",
                idCollection.size
              )}
            </Checkbox>
          </Divider>
          <ul className="mx-0 px-0">
            {entityIds.map((entityId) => {
              const matchedEntity = entitiesByIds[entityId];

              let blockText = analysis.blocks[matchedEntity.block_idx].text;
              if (matchedEntity.class === entityClass.TABLE) {
                const { table_idx, column_idx, row_idx } = matchedEntity;
                blockText = analysis.tables[table_idx][row_idx][column_idx];
              }

              const annotatedText = Annotation.singleNode(
                blockText,
                matchedEntity
              );

              return (
                <li key={entityId} className="text-ellipsis py-5">
                  <Checkbox
                    onChange={handleCheckbox(entityId)}
                    checked={idCollection.has(entityId)}
                    disabled={disableList || processing}
                  >
                    <DisplayTip
                      title={!(disableList || processing) ? annotatedText : ""}
                      visible={!(disableList || processing) ? undefined : false}
                    >
                      <span
                        className="text-ellipsis"
                        style={{ fontSize: "0.75rem" }}
                      >
                        {annotatedText}
                      </span>
                    </DisplayTip>
                  </Checkbox>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EntityTypeOptions;
