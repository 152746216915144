import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  analysisModes,
  HIDE_ALL_RELATIONSHIPS,
  SHOW_ALL_RELATIONSHIPS,
} from "../../../../constants";
import infoMessages from "../../../../utils/messages/infoMessages";
import ActionTip from "../../../common/widgets/ActionTip";
import { deleteAllRelationship, relationshipVisibility } from "./handlers";
import Mixpanel from "../../../../utils/services/Mixpanel";
import events from "../../../../constants/analyticEvents";
import { useEffect } from "react";

const { GRAPH } = analysisModes;

const RelationshipWidget = ({ loading }) => {
  const dispatch = useDispatch();
  const {
    analysis: { document, analysisMode },
    relationship,
    analysisApi,
  } = useSelector((state) => state);

  const isGraphView = analysisMode === GRAPH;
  const shouldDisable =
    !document.title || !relationship.relations.length || isGraphView;

  useEffect(() => {
    if (isGraphView) relationshipVisibility(HIDE_ALL_RELATIONSHIPS, dispatch);
  }, [analysisMode]);

  return (
    <span
      key={SHOW_ALL_RELATIONSHIPS}
      className="all-relationship-options mr-2"
    >
      Relationship ({relationship.relations.length}){" "}
      {relationship.visibility === HIDE_ALL_RELATIONSHIPS ? (
        <ActionTip title={infoMessages.show_all}>
          <Button
            size="small"
            disabled={shouldDisable}
            onClick={() => {
              relationshipVisibility(SHOW_ALL_RELATIONSHIPS, dispatch);
              Mixpanel.track(events.TOGGLE_ON_RELATIONSHIPS, document);
            }}
          >
            <EyeOutlined />
          </Button>
        </ActionTip>
      ) : (
        <ActionTip title={infoMessages.hide_all}>
          <Button
            size="small"
            disabled={shouldDisable}
            onClick={() => {
              relationshipVisibility(HIDE_ALL_RELATIONSHIPS, dispatch);
              Mixpanel.track(events.TOGGLE_OFF_RELATIONSHIPS, document);
            }}
          >
            <EyeInvisibleOutlined />
          </Button>
        </ActionTip>
      )}
      <ActionTip title={infoMessages.delete_all}>
        <Button
          size="small"
          disabled={
            analysisApi.deleteRelationship.loading ||
            loading ||
            !document.title ||
            !relationship.relations.length
          }
          onClick={() =>
            deleteAllRelationship(document.id, relationship, dispatch)
          }
        >
          <DeleteOutlined />
        </Button>
      </ActionTip>
    </span>
  );
};

export default RelationshipWidget;
