import { notification, Button } from "antd";

/**
 * MessageBox class
 *
 * @class
 *
 * Defines instance methods for displaying messages.
 */
class MessageBox {
  constructor(options = {}) {
    notification.config({
      placement: "bottomLeft",
      message: "",
      duration: 5,
      ...options,
    });
  }

  /**
   * show - Display default message box view
   *
   * @method
   *
   * @param {string | ReactNode} content The mesage for display
   * @param {string | ReactNode} title The title of the display
   * @param {object} options Other options for configuration. See https://ant.design/components/notification#api
   *
   * @returns void
   */
  show(content = "", title = "", options = {}) {
    const { actions = [], needConfirm = false, ...restOptions } = options;
    if (needConfirm)
      actions.push({ label: "Dismiss", onClick: notification.destroy });

    const desc = (
      <span>
        {content}{" "}
        {actions.map(({ label, onClick }) => (
          <Button
            className="message-box-action-btn"
            size="small"
            onClick={() => {
              onClick();
              notification.destroy();
            }}
          >
            {label}
          </Button>
        ))}
      </span>
    );
    notification.open({
      className: "message-box-show",
      message: title,
      description: desc,
      duration: needConfirm ? 0 : undefined,
      ...restOptions,
    });
  }

  /**
   * showError - Displays message box error view
   *
   * @method
   *
   * @param {string | ReactNode} content The mesage for display
   * @param {string | ReactNode} title The title of the display
   * @param {object} options Other options for configuration. See https://ant.design/components/notification#api
   *
   * @returns void
   */
  showError(content = "", title = "", options = {}) {
    notification.open({
      className: "message-box-show-error",
      message: title,
      description: content,
      ...options,
    });
  }
}

const messageBox = new MessageBox();

export default messageBox;
