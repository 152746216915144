import { Dropdown, Button, Menu } from "antd";
import { DownOutlined, BarChartOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { analysisModes, documentStatus } from "../../../constants";
import { setAnalysisMode } from "../../../store/actions";
import ConfidenceScoreFilter from "./ConfidenceScoreFilter";
import { useState } from "react";
import DocumentPerformanceOverview from "./DocumentPerformanceOverview";

const { ANNOTATION, GRAPH } = analysisModes;
const { COMPLETED } = documentStatus;

/**
 * AnalysisPanel
 *
 * @returns React JSX
 */
const AnalysisPanel = ({ children }) => {
  const {
    analysis: { analysisMode, document },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [overview, setOverview] = useState(false);

  const isCompleted = document.status === COMPLETED;

  const modeMenu = (
    <Menu
      onClick={({ key: mode }) => {
        dispatch(setAnalysisMode(mode));
      }}
    >
      <Menu.Item key={ANNOTATION}>{ANNOTATION}</Menu.Item>
      <Menu.Item key={GRAPH}>{GRAPH}</Menu.Item>
    </Menu>
  );

  return (
    <div className="analysis-panel">
      {!!overview && (
        <DocumentPerformanceOverview
          documentId={document.id}
          hideOverview={() => setOverview(false)}
          isCompleted={isCompleted}
        />
      )}
      {!overview && (
        <>
          <div className="section-card" style={{ zIndex: 1 }}>
            <div className="section-card-body justify-between">
              <div>
                <Dropdown overlay={modeMenu} trigger={["click"]}>
                  <Button>
                    {analysisMode} <DownOutlined />
                  </Button>
                </Dropdown>
                {analysisMode === ANNOTATION && <ConfidenceScoreFilter />}
              </div>
              {!!document.hasExtractionTime && (
                <div>
                  <Button onClick={() => setOverview(true)}>
                    <BarChartOutlined /> Performance
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="full-height">{children}</div>
        </>
      )}
    </div>
  );
};

export default AnalysisPanel;
