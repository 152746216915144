import { batch } from "react-redux";
import { entityStatus } from "../../../constants/index.js";
import {
  acceptEntityRequest,
  rejectEntityRequest,
  rejectEntityNoInterestRequest,
  rejectEntityNotCorrectRequest,
} from "../../../store/actions/apiRequests.js";
import {
  addProcessingEntities,
  removeActiveSelection,
} from "../../../store/actions/index.js";

const { ACCEPTED } = entityStatus;
const REJECT_THIS_TIME = "REJECT_THIS_TIME";
const REJECT_NO_INTEREST = "REJECT_NO_INTEREST";
const REJECT_INCORRECT = "REJECT_INCORRECT";

export const handleAcceptRejectEntityMenu =
  (entityId, documentId, dispatch) =>
  ({ key, domEvent }) => {
    domEvent.stopPropagation();

    let requestAction;
    switch (key) {
      case ACCEPTED:
        requestAction = acceptEntityRequest(documentId, entityId);
        break;

      case REJECT_THIS_TIME:
        requestAction = rejectEntityRequest(documentId, entityId);
        break;

      case REJECT_NO_INTEREST:
        requestAction = rejectEntityNoInterestRequest(documentId, entityId);
        break;

      case REJECT_INCORRECT:
        requestAction = rejectEntityNotCorrectRequest(documentId, entityId);
        break;

      default:
        return;
    }

    batch(() => {
      dispatch(requestAction);
      dispatch(addProcessingEntities({ [entityId]: true }));
      dispatch(removeActiveSelection());
    });
  };
