import messageBox from "../utils/services/MessageBox";
import { storage } from "../utils/services/StaticStorage";
import { storageIds, RULE_TYPES } from "../constants";
import { createRuleRequest } from "../store/actions/manualRules";
import successMessages from "../utils/messages/successMessages";
import routes from "../constants/routes";
import { showRuleFeedbackForm } from "../components/pages/rulesManager/helpers";

export const addEntityMessageBox = (dispatch) => {
  const { name, type } = storage.get(storageIds.ADD_ENTITY);
  const rule = {
    entity_instance: name,
    entity_type: type,
    rule: RULE_TYPES.annotate.value,
  };

  messageBox.show(successMessages.entity_added_accepted, "", {
    needConfirm: true,
    actions: [
      {
        label: "Add as rule",
        onClick: () => dispatch(createRuleRequest(rule)),
      },
    ],
  });
  storage.delete(storageIds.ADD_ENTITY);
};

export const changeEntityMessageBox = (dispatch) => {
  const { text, type } = storage.get(storageIds.CHANGE_ENTITY);
  const rule = {
    entity_instance: text,
    entity_type: type,
    rule: RULE_TYPES.annotate.value,
  };

  const changeMsg = `Entity type changed. Would you like to always annotate this as a ${type}`;
  messageBox.show(changeMsg, "", {
    needConfirm: true,
    actions: [
      {
        label: "Add as rule",
        onClick: () => dispatch(createRuleRequest(rule)),
      },
    ],
  });
  storage.delete(storageIds.CHANGE_ENTITY);
};

export const rejectEntityAddRuleMessageBox = (
  dispatch,
  history,
  ruleId,
  notCorrect = false
) => {
  const options = {
    needConfirm: notCorrect,
    actions: [
      {
        label: "Rules",
        onClick: () => history.push(routes.RULES_MANAGER),
      },
    ],
  };
  if (notCorrect) {
    options.actions.push({
      label: "Submit feedback",
      onClick: () => showRuleFeedbackForm(ruleId, dispatch),
    });
  }

  console.log("renc.result.data", ruleId);

  messageBox.show(successMessages.entity_rejected_rule, "", options);
};
