import { Button, Popover, Divider, Menu } from "antd";
import {
  CaretDownFilled,
  CaretRightFilled,
  LogoutOutlined,
  UserOutlined,
  BankOutlined,
  ProfileOutlined,
  MergeCellsOutlined,
  AppstoreAddOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import UserAvatar from "../widgets/UserAvatar";
import { useAuthContext } from "../../../contexts/AuthContext";
import { useState } from "react";
import { useHistory } from "react-router";
import routes from "../../../constants/routes";
import { appRoles } from "../../../constants";
import { performanceDashboardSupported } from "../../../utils/helpers";

const HeaderOptions = () => {
  const [dropDownOpened, setDropDownOPened] = useState(false);

  const { userDetails, auth } = useAuthContext();

  const popoverProps = {
    placement: "bottomRight",
    content: <Options userDetails={userDetails} auth={auth} />,
    trigger: "click",
  };

  if (!(auth && auth.authenticated)) return null;
  return (
    <div className="header-user-info">
      <div className="header-user-avatar">
        <Popover
          {...popoverProps}
          onVisibleChange={(isOpened) => setDropDownOPened(isOpened)}
        >
          <UserAvatar name={userDetails.fullName} />
          <Button type="text" size="small">
            {userDetails.firstName}
          </Button>
          <Button type="text" size="small" style={{ marginLeft: "-12px" }}>
            {!dropDownOpened && <CaretRightFilled style={{ color: "#777" }} />}
            {dropDownOpened && <CaretDownFilled style={{ color: "#777" }} />}
          </Button>
        </Popover>
      </div>
    </div>
  );
};

export default HeaderOptions;

const Options = ({ userDetails, auth }) => {
  const history = useHistory();

  const userRoles = auth && auth.realmAccess ? auth.realmAccess.roles : [];

  return (
    <div style={{ textAlign: "center" }}>
      <div>
        <strong>{userDetails.fullName}</strong> <br />
        <small>{userDetails.email}</small> <br />
        <small>{userDetails.organizationId}</small>
      </div>
      <Divider style={{ margin: "10px" }} />
      <div className="user-options">
        <Menu>
          <Menu.Item
            key={routes.PROFILE}
            className="list-item"
            onClick={() => history.push(routes.PROFILE)}
            icon={<UserOutlined />}
          >
            Profile
          </Menu.Item>
          {userRoles.includes(appRoles.MANAGE_ORG_SETTINGS) && (
            <Menu.Item
              key={routes.ORGANISATION}
              className="list-item"
              onClick={() => history.push(routes.ORGANISATION)}
              icon={<BankOutlined />}
            >
              Organisation
            </Menu.Item>
          )}
          {performanceDashboardSupported() && (
            <Menu.Item
              key={routes.PERFORMANCE_DASHBOARD}
              className="list-item"
              onClick={() => history.push(routes.PERFORMANCE_DASHBOARD)}
              icon={<BarChartOutlined />}
            >
              Performance
            </Menu.Item>
          )}
          <Menu.Item
            key={routes.RULES_MANAGER}
            className="list-item"
            onClick={() => history.push(routes.RULES_MANAGER)}
            icon={<AppstoreAddOutlined />}
          >
            Rules
          </Menu.Item>
          {userRoles.includes(appRoles.ORGANISATION_METADATA) && (
            <Menu.Item
              key={routes.METADATA}
              className="list-item"
              onClick={() => history.push(routes.METADATA)}
              icon={<ProfileOutlined />}
            >
              Metadata
            </Menu.Item>
          )}
          {userRoles.includes(appRoles.MANAGE_ORG_COLLECTIONS) && (
            <Menu.Item
              key={routes.COLLECTIONS}
              className="list-item"
              onClick={() => history.push(routes.COLLECTIONS)}
              icon={<MergeCellsOutlined />}
            >
              Collections
            </Menu.Item>
          )}
          <Menu.Divider style={{ margin: "10px" }} />
          <Menu.Item
            key="logout"
            className="list-item"
            onClick={() => auth.logout()}
            icon={<LogoutOutlined />}
          >
            Logout
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
};
