import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import ActionTip from "../../common/widgets/ActionTip";
import { handleEdit, handleDelete, handleGraph } from "./helpers";
import { useAuthContext } from "../../../contexts/AuthContext";
import { handleDownload } from "../../../utils/helpers";

const DELETE_COLLECTION = "DELETE_COLLECTION";
const EDIT_COLLECTION = "EDIT_COLLECTION";
const COLLECTION_GRAPH = "COLLECTION_GRAPH";
const DOWNLOAD_ATTACK_NAVIGATOR = "DOWNLOAD_ATTACK_NAVIGATOR";
const BASE_URL = window._env_.UNIFIED_SERVER_URL;

const MoreActions = ({ collectionId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { auth } = useAuthContext();

  const ttpsUrl = `${BASE_URL}/organisation/collections/${collectionId}/navigator_ttps`;
  const filename = `collection_${collectionId}_navigator_ttps`;

  const menu = (
    <Menu key={collectionId}>
      <Menu.Item
        key={EDIT_COLLECTION}
        onClick={() => handleEdit(collectionId, dispatch)}
      >
        Edit
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={DELETE_COLLECTION}
        onClick={() => handleDelete(collectionId, dispatch)}
      >
        Delete
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={COLLECTION_GRAPH}
        onClick={() => handleGraph(collectionId, history)}
      >
        Graph
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={DOWNLOAD_ATTACK_NAVIGATOR}
        onClick={handleDownload(ttpsUrl, filename, auth.token)}
      >
        Download ATT&CK Navigator layer
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        key={collectionId}
        onClick={(e) => e.stopPropagation()}
        overlay={menu}
        trigger={["click"]}
        destroyPopupOnHide={true}
      >
        <ActionTip title="More actions">
          <EllipsisOutlined
            style={{ fontSize: "1.5rem" }}
            onClick={(e) => e.stopPropagation()}
          />
        </ActionTip>
      </Dropdown>
    </>
  );
};

export default MoreActions;
