import { Menu } from "antd";
import { useSelector } from "react-redux";
import { entityClass } from "../../../constants";
import Annotation from "../../../utils/services/Annotation";

const REJECT_THIS_TIME = "REJECT_THIS_TIME";
const REJECT_NO_INTEREST = "REJECT_NO_INTEREST";
const REJECT_INCORRECT = "REJECT_INCORRECT";

export const RejectEntityMenuItems = ({ onClick }) => {
  return (
    <Menu onClick={onClick}>
      <Menu.Item key={REJECT_THIS_TIME}>Reject this time</Menu.Item>
      <Menu.Divider />
      <Menu.Item key={REJECT_NO_INTEREST} className="lh-20">
        Always reject <br /> <small>I'm not interested in this entity</small>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={REJECT_INCORRECT} className="lh-20">
        Always reject <br /> <small>This extraction is incorrect</small>
      </Menu.Item>
    </Menu>
  );
};

export const SingleEntityAnnotation = ({ entityId }) => {
  const analysis = useSelector(({ analysis }) => analysis);

  const entity = analysis.entitiesByIds[entityId];

  let blockText = analysis.blocks[entity.block_idx].text;
  if (entity.class === entityClass.TABLE) {
    const { table_idx, column_idx, row_idx } = entity;
    blockText = analysis.tables[table_idx][row_idx][column_idx];
  }

  const annotatedText = Annotation.singleNode(blockText, entity);
  return <>{annotatedText}</>;
};
