import { TreeSelect } from "antd";
import { BulbFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { ATTACK_PATTERN, SDO_LOCATION } from "../../../constants";
import SelectCountry from "./ContextOptions/SelectCountry";
import SelectTID from "./ContextOptions/SelectTID";
import countries from "../../../data/countries";

const { TreeNode } = TreeSelect;

const EntitySelectInput = ({ selectedType, handleChange, ...restProps }) => {
  // App state
  const { entityProps } = useSelector(({ analysis }) => analysis);

  const {
    selectedTID,
    setSelectedTID,
    selectedCountryCode,
    setSelectedCountryCode,
    ...otherProps
  } = restProps;

  const getLabelText = (value) => {
    const entityLabel = entityProps[value]?.label;
    if (value == ATTACK_PATTERN)
      return `${entityLabel}${selectedTID && " -> " + selectedTID}`;
    if (value == SDO_LOCATION)
      return `${entityLabel}${
        selectedCountryCode &&
        " -> [" + selectedCountryCode + "] " + countries[selectedCountryCode]
      }`;
    if (selectedType.endsWith(`::${value}`)) {
      const [type, sub_type] = selectedType.split("::");
      return `${entityProps[type]?.label} -> ${sub_type}`;
    }
    return entityLabel || value;
  };

  return (
    <div className="mt-30 mb-15 change-type-tree-select">
      <TreeSelect
        showSearch
        style={{ width: "100%" }}
        value={selectedType}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        placeholder="Select entity type"
        allowClear
        onChange={handleChange}
        treeDefaultExpandAll
        treeLine
        {...otherProps}
      >
        {Object.values(entityProps).map((entity) => (
          <TreeNode
            key={entity.value}
            value={entity.value}
            disabled
            title={<>{getLabelText(entity.value)}</>}
          >
            {entity.subcategories.map((subType) => {
              if (entity.value === ATTACK_PATTERN) {
                return (
                  <TreeNode
                    key={`${entity.value}::${subType}`}
                    value={`${entity.value}::${subType}`}
                    title={
                      <>
                        <SelectTID
                          onClick={(e) => e.stopPropagation()}
                          selectedTID={selectedTID}
                          setSelectedTID={setSelectedTID}
                          defaultOpen={false}
                        />
                      </>
                    }
                  />
                );
              } else if (
                entity.value === SDO_LOCATION &&
                subType === "country"
              ) {
                return (
                  <TreeNode
                    key={`${entity.value}::${subType}`}
                    value={`${entity.value}::${subType}`}
                    title={
                      <>
                        <SelectCountry
                          onClick={(e) => e.stopPropagation()}
                          setSelectedCountryCode={setSelectedCountryCode}
                          defaultOpen={false}
                        />
                      </>
                    }
                  />
                );
              }
              return (
                <TreeNode
                  key={`${entity.value}::${subType}`}
                  value={`${entity.value}::${subType}`}
                  title={
                    <>
                      <BulbFilled style={{ color: entity.color }} />{" "}
                      {getLabelText(subType)}
                    </>
                  }
                />
              );
            })}
          </TreeNode>
        ))}
      </TreeSelect>
    </div>
  );
};

export default EntitySelectInput;
