import { combineReducers } from "redux";
import analysis from "./analysis";
import relationship from "./relationship";
import selection from "./selection";
import user from "./user";
import loader from "./loader";
import analysisApi from "./analysisApi";
import metadata from "./metadata";
import documentMetadata from "./documentMetadata";
import stixGraph from "./stixGraph";
import collections from "./collections";
import manualRules from "./manualRules";
import dashboard from "./dashboard";

export default combineReducers({
  user,
  analysis,
  relationship,
  selection,
  loader,
  analysisApi,
  metadata,
  documentMetadata,
  stixGraph,
  collections,
  manualRules,
  dashboard,
});
