import {
  generateApiActionTypes,
  initializeState,
} from "../../utils/helpers/reduxStore";

import actions from "../actions/apiRequests";

const actionTypes = {
  [actions.ADD_ENTITY]: "addEntity",
  [actions.ACCEPT_ENTITY]: "acceptEntity",
  [actions.REJECT_ENTITY]: "rejectEntity",
  [actions.REJECT_ENTITY_NO_INTEREST]: "rejectEntityNoInterest",
  [actions.REJECT_ENTITY_NOT_CORRECT]: "rejectEntityNotCorrect",
  [actions.ACCEPT_BULK_ENTITIES]: "acceptBulkEntities",
  [actions.REJECT_BULK_ENTITIES]: "rejectBulkEntities",
  [actions.DELETE_RELATIONSHIP]: "deleteRelationship",
  [actions.ADD_RELATIONSHIP]: "addRelationship",
  [actions.CHANGE_SINGLE_ENTITY]: "changeEntity",
  [actions.CHANGE_MULTIPLE_ENTITIES]: "changeMultipleEntities",
  [actions.ANALYSIS_DETAILS]: "analysisDetails",
  [actions.GET_DOCUMENT_TLP]: "documentTlp",
  [actions.ADD_DOCUMENT_TLP]: "addDocumentTlp",
  [actions.DELETE_DOCUMENT_TLP]: "deleteDocumentTlp",
};

const apiActionTypes = generateApiActionTypes(actionTypes);

const stateInit = initializeState(actionTypes);

const analysisApi = (state = stateInit, action = {}) => {
  const typeState = apiActionTypes[action.type];

  if (typeState)
    return {
      ...state,
      [typeState]: action.payload,
    };

  if (action.type === actions.RESET_ANALYIS_API_STATE) return stateInit;

  return state;
};

export default analysisApi;
