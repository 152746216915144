import { Menu, Dropdown } from "antd";
import {
  FileTextOutlined,
  EditOutlined,
  RadarChartOutlined,
  DeleteOutlined,
  MoreOutlined,
  FolderOutlined,
} from "@ant-design/icons";
import { BiUserCircle } from "react-icons/bi";
import { useDrag } from "react-dnd";
import {
  dragItems,
  documentStatus,
  documentAccessLevels,
} from "../../../constants";
import WarningDialog from "../../common/widgets/WarningDialog";
import ActionTip from "../../common/widgets/ActionTip";
import useAxios from "../../../hooks/useAxios";
import { useColumnsReload, useReload } from "../../../hooks/useReload";
import { handleDeleteDocument } from "../../../utils/helpers";
import DisplayTip from "../../common/widgets/DisplayTip";
import routes from "../../../constants/routes";
import infoMessages from "../../../utils/messages/infoMessages";
import NavButton from "../../common/widgets/NavButton";
import { useDocumentContext } from "../../../contexts/DocumentContext";
import { showSlideInView } from "../../../store/actions";
import { useDispatch } from "react-redux";
import DocumentCollections from "../collections/DocumentCollections";
import {
  useMessageBoxError,
  useMessageBoxSuccess,
} from "../../../hooks/useMessageBox";
import successMessages from "../../../utils/messages/successMessages";

const { org_doc_access_tip, private_doc_access_tip } = infoMessages;
const { COMPLETED } = documentStatus;

const DocumentCard = ({ document, index }) => {
  const { setDocumentData } = useDocumentContext();
  const { document_id, status, access_level } = document;
  const [{ result, error }, updateDoc] = useAxios(null, "PUT");
  const dispatch = useDispatch();
  useReload(result, () => {
    setDocumentData((prevState) => {
      const newState = { ...prevState };
      newState[status] = newState[status].filter(
        (doc) => doc.document_id !== document_id
      );
      return newState;
    });
  });

  useColumnsReload(result, [status]);

  const errMsg = error && error.details ? error.details.status : null;
  useMessageBoxError(error, errMsg);
  useMessageBoxSuccess(result, successMessages.document_removed);

  const isCompleted = status === COMPLETED;

  const [{ isDragging }, drag] = useDrag({
    item: { type: dragItems.CARD, document, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: () => !(isCompleted || document.pending),
  });

  let style = {};
  if (isCompleted) style = { cursor: "default" };
  if (isDragging) style = { cursor: "grabbing" };
  if (document.pending) style["backgroundColor"] = "#efefef";

  let sourceUrl = document.source;
  if (!sourceUrl || `${document.source_type}`.toLowerCase() !== "url") {
    sourceUrl = routes.DOCUMENT_SOURCE.replace(":documentId", document_id);
  }

  const analysisUrl = routes.DOCUMENT_ANALYSIS.replace(
    ":documentId",
    document_id
  );

  const graphUrl = routes.STIX_GRAPH.replace(":documentId", document_id);

  const moreMenu = (
    <Menu>
      <Menu.Item
        icon={<FolderOutlined />}
        onClick={() => {
          dispatch(
            showSlideInView(
              DocumentCollections,
              { documentId: document_id, documentTitle: document.title },
              { title: "Manage Collections" }
            )
          );
        }}
      >
        Manage Collections
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="document-card" style={style} ref={drag}>
      <div className="document-card-header">
        <div className="document-card-header-info">
          <DisplayTip
            title={
              access_level === documentAccessLevels.ORGANISATION
                ? org_doc_access_tip
                : private_doc_access_tip
            }
          >
            {access_level === documentAccessLevels.PRIVATE ? (
              <BiUserCircle />
            ) : (
              ""
            )}
          </DisplayTip>
        </div>
        <div className="justify-end">
          <div className="document-card-header-delete">
            <WarningDialog
              title="Are you sure you want to remove this document?"
              onConfirm={() => handleDeleteDocument(document_id, updateDoc)}
              okText="Yes"
              cancelText="No"
            >
              <ActionTip title="Remove document">
                <DeleteOutlined />
              </ActionTip>
            </WarningDialog>
          </div>
          {isCompleted && (
            <Dropdown overlay={moreMenu} trigger={["click"]}>
              <MoreOutlined style={{ fontSize: "18px" }} />
            </Dropdown>
          )}
        </div>
      </div>
      <div className="document-card-title">
        <DisplayTip title={document.title}>{document.title}</DisplayTip>
      </div>
      <div className="document-card-actions">
        <ActionTip title="View source">
          <NavButton
            url={sourceUrl}
            text="Source"
            icon={<FileTextOutlined />}
            linkProps={{
              target: "_blank",
              rel: "noreferrer",
              onClick: undefined,
            }}
            buttonProps={{ className: "document-source", type: "link" }}
          />
        </ActionTip>
        {isCompleted && (
          <>
            <NavButton
              className="mr-5"
              url={graphUrl}
              text="Graph"
              icon={<RadarChartOutlined />}
            />
            <NavButton url={analysisUrl} text="Analysis" />
          </>
        )}
        {!isCompleted && (
          <NavButton url={analysisUrl} text="Analyse" icon={<EditOutlined />} />
        )}
      </div>
    </div>
  );
};

export default DocumentCard;
